.headerSection {
  position: relative;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: calc(var(--mantine-spacing-xl) * 3);
  padding-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.section {
  padding: 0px 60px !important;
  @media (max-width: 850px) {
    padding: 0px 24px !important;
  }
}

.paddingPage {
  padding: 0px 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.innerHeader {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.contentHeader {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  margin-right: calc(var(--mantine-spacing-xl) * 3);

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.titleHeader {
  font-size: 40px !important;
  line-height: 48px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 768px) {
    font-size: 28px !important;
    line-height: 36px !important;
  }
}

.captionHeader {
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 500 !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.buttonHeader {
  background-color: red !important;
  height: 45px;
}

.defaultSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 3);
  padding-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.dateSection {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.priceSection {
  font-size: 22px;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.itemCurriculum {
  border: 1px solid transparent;
  position: relative;
  z-index: 0;
  transition: transform 300ms ease;

  &[data-active] {
    padding: 5px;
    z-index: 1;
    background-color: rgb(237, 242, 255);
    border-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
    box-shadow: var(--mantine-shadow-md);
    border-radius: var(--mantine-radius-md);
  }
}