.trainingSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.section {
  padding: 40px 60px !important;
  @media (max-width: 850px) {
    padding: 40px 24px !important;
  }
}

.paddingPage {
  padding: 0px 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.titleHeader {
  font-size: 30px !important;
  line-height: 38px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 768px) {
    font-size: 18px !important;
    line-height: 26px !important;
  }
}

.captionHeader {
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 500 !important;

  @media (max-width: 768px) {
    font-size: 12px !important;
    line-height: 20px !important;
  }
}

.searchTrainingBox {
  margin-top: 20px;
  width: 70%;
}

.gridProgramCard {
  padding: 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}